import React, { useEffect, useState } from 'react'
import { Box, Typography, makeStyles, withStyles } from '@material-ui/core'
import {
  Paper,
  Panel,
  Spinner,
  Notification,
  HomePagePanel,
  BackButton
} from 'src/components'
import useLandingPageLogic from './hooks/useLandingPageLogic'
import FMLogo from 'src/assets/images/fm-logo-2.png'
import PudoLogo from 'src/assets/images/pudo-logo.png'

const useStyles = makeStyles(({ palette, spacing }) => ({
  h1: {
    color: palette.primary.main,
    textAlign: 'center'
  },
  faq: {
    textAlign: 'left',
    width: 332,
    fontFamily: 'Open Sans',
    fontSize: '19px',
    color: '#6D6E71'
  },
  backButton: {
    padding: spacing(0.5, 1.5),
    marginTop: '12px',
    marginLeft: '4px',
    position: 'absolute',
    background: 'white',
    borderRadius: '20px'
  }
}))

// const menuList = [
//   { icon: 'food', topic: 'My Order', path: '/order' },
//   { icon: 'message', topic: 'Chat History', path: '/chat' }
// ]

const Row = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box)

export default function LandingPage(props) {
  //handle EC Help Center Homepage
  const [isPageChecked, setIsPageChecked] = useState(false)
  const [isEcApp, setIsEcApp] = useState(false)

  const {
    menu,
    faq,
    isLoading,
    hasNewMessage,
    isTngMp,
    userPlatform,
    notify,
    setNotify
  } = useLandingPageLogic(
    'mobile',
    props,
    isPageChecked,
    setIsPageChecked,
    setIsEcApp
  )

  const styles = useStyles()
  return (
    <Box>
      {isEcApp ? (
        <Box
          height={85}
          mt={3}
          mb={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <Box mb={1}>
            {userPlatform === 'pudoparcel' ? (
              <img width="50px" height="50px" src={PudoLogo} alt="logo" />
            ) : (
              <img width="50px" height="50px" src={FMLogo} alt="logo" />
            )}
          </Box>
          <Typography variant="h1" className={styles.h1}>
            How can we help you?
          </Typography>
        </Box>
      ) : (
        <Row height={50} mt={2}>
          <Typography variant="h1" className={styles.h1}>
            How can we help you?
          </Typography>
        </Row>
      )}
      {isLoading && <Spinner />}
      {!isLoading && (
        <Box>
          {global.jwtToken && (
            <Row my={2}>
              <Paper>
                {menu &&
                  menu.length > 0 &&
                  menu.map((item, idx) => {
                    if (idx == 2) {
                      return (
                        <Panel
                          {...props}
                          icon={item.icon}
                          topic={item.topic}
                          path={item.path}
                          key={idx}
                          item={item}
                          chip={hasNewMessage}
                          platform={userPlatform}
                        />
                      )
                    } else
                      return (
                        <Panel
                          {...props}
                          icon={item.icon}
                          topic={item.topic}
                          path={item.path}
                          key={idx}
                          item={item}
                          platform={userPlatform}
                        />
                      )
                  })}
              </Paper>
            </Row>
          )}
          <Box mx={3} display="flex" justifyContent="center">
            <Typography variant="h3" className={styles.faq}>
              FAQ
            </Typography>
          </Box>
          <Row my={2}>
            <Paper>
              {faq &&
                faq.length > 0 &&
                faq.map((item, idx) => {
                  return (
                    <Panel
                      {...props}
                      icon={item.icon}
                      topic={item.category}
                      path={item.path}
                      key={idx}
                      item={item}
                      platform={userPlatform}
                    />
                  )
                })}
            </Paper>
          </Row>
          {global.jwtToken && (
            <Box mb={2} pb={2}>
              <Box mx={3} display="flex" justifyContent="center">
                <Typography variant="h3" className={styles.faq}>
                  FEEDBACK
                </Typography>
              </Box>
              <Row my={2}>
                <Paper>
                  <Panel
                    {...props}
                    icon={'info'}
                    topic={'I have other issue to feedback'}
                    path={'/f'}
                    item={{ platform: userPlatform }}
                    platform={userPlatform}
                  />
                </Paper>
              </Row>
            </Box>
          )}
        </Box>
      )}
      <Notification
        show={notify.show}
        severity={notify.severity}
        message={notify.message}
        onClose={() => setNotify({ show: false, severity: '', message: '' })}
      />
    </Box>
  )
}
