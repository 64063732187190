import React from 'react'
import { Box, Typography, makeStyles, withStyles } from '@material-ui/core'
import { Paper, Button, BackButton, Panel } from 'src/components'
import useFaqPageLogic from './hooks/useFaqPageLogic'

const useStyles = makeStyles(({ palette, spacing }) => ({
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  topic: {
    fontWeight: 'bold',
    color: palette.grey['100']
  },
  faqMsg: {
    margin: spacing(2.5, 0)
  },
  h1: {
    color: palette.primary.main,
    textAlign: 'center'
  },
  faq: {
    fontFamily: 'Open Sans',
    fontSize: '19px',
    margin: spacing(0, 3),
    color: '#6D6E71',
    textAlign: 'center'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box)

// const msg =
//   'We’re sorry there was a missing item from your order. We can try to offer you a refund or we can ask the outlet if they can send the correct items out again. Be aware that delivery is only possible with main meals and may take between 30 and 60 minutes to arrive'
// const msgArr = msg.split('\n')

export default function FAQPage(props) {
  const { location } = props
  const { state } = location

  const { faqList, isTngMp, userPlatform } = useFaqPageLogic(
    'mobile',
    location,
    ''
  )

  const styles = useStyles()
  return (
    <Box p={2}>
      {!isTngMp ? <BackButton {...props} backTo={'/'} /> : null}
      <Row height={50}>
        <Typography variant="h1" className={styles.h1}>
          {state.category}
        </Typography>
      </Row>
      <Row my={1.5}>
        <Paper>
          {faqList &&
            faqList.length > 0 &&
            faqList.map((item, idx) => {
              return (
                <Panel
                  {...props}
                  // icon={item.icon}
                  topic={item.topic}
                  path={item.path}
                  key={idx}
                  item={item}
                  id={item.faqId}
                  platform={userPlatform}
                />
              )
            })}
        </Paper>
      </Row>
    </Box>
  )
}

//previous version *

// const useStyles = makeStyles(({ palette, spacing }) => ({
//   center: {
//     display: 'flex',
//     justifyContent: 'center'
//   },
//   topic: {
//     fontWeight: 'bold',
//     color: palette.grey['100']
//   },
//   faqMsg: {
//     margin: spacing(2.5, 0)
//   },
//   backButton: {
//     color: palette.primary.main
//   }
// }))

// // const msg =
// //   'We’re sorry there was a missing item from your order. We can try to offer you a refund or we can ask the outlet if they can send the correct items out again. Be aware that delivery is only possible with main meals and may take between 30 and 60 minutes to arrive'
// // const msgArr = msg.split('\n')

// export default function FAQPage(props) {
//   const { location } = props
//   const { state } = location

//   const message = state.answer
//   const msgArr = message.split('\n')

//   const styles = useStyles()
//   return (
//     <Box className={styles.center} my={3}>
//       <Paper display="block" space={4} style={{ width: '75%' }}>
//         <BackButton {...props} />
//         <Typography variant="body1" className={styles.topic}>
//           {state.topic}
//         </Typography>
//         {msgArr &&
//           msgArr.length > 0 &&
//           msgArr.map((str, idx) => {
//             return (
//               <Typography variant="body1" className={styles.faqMsg} key={idx}>
//                 {str}.
//               </Typography>
//             )
//           })}
//         {state.call && (
//           <Box className={styles.center} pt={5}>
//             <Button title="Call Us" handleClick={() => console.log('call us')} />
//           </Box>
//         )}
//       </Paper>
//     </Box>
//   )
// }
