import React, { useEffect, useRef } from 'react'
import {
  Box,
  Typography,
  makeStyles,
  withStyles,
  Backdrop,
  List,
  Divider,
  TextField,
  IconButton,
  Dialog,
  Button as MuiButton,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  DialogTitle as MuiDialogTitle,
  ListItem,
  CircularProgress,
  InputAdornment,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import _ from 'lodash'
import {
  Button,
  BackButton,
  Spinner,
  TextFieldInput,
  ImageInput,
  Notification
} from 'src/components'
import useInactivePageLogic from '../hooks/useInactivePageLogic'
import moment from 'moment'
import InfoIcon from '@material-ui/icons/Info'
import SubjectInfo from '../constants/SubjectInfo'
import { Autocomplete } from '@material-ui/lab'
import fmLogo from 'src/assets/images/FamilyMartLogo.png'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    color: palette.grey[100],
    fontSize: '0.875rem'
  },
  backdrop: {
    zIndex: 10,
    color: '#fff'
  },
  modalImgBox: {
    width: 300,
    maxHeight: 530
  },
  modalImg: {
    width: '100%',
    maxHeight: 530,
    objectFit: 'contain'
  },
  remove: {
    textAlign: 'center',
    marginTop: spacing(1),
    padding: spacing(1, 2),
    backgroundColor: palette.warning.main,
    color: palette.common.white
  },
  close: {
    textAlign: 'center',
    marginTop: spacing(1),
    padding: spacing(1, 2),
    backgroundColor: palette.success.main,
    color: palette.common.black
  },
  body1: {
    margin: spacing(2.5, 0),
    fontSize: '0.875rem'
  },
  dateInput: {
    borderRadius: 5,
    border: '1px solid rgb(207, 207, 207)',
    fontFamily: 'Open Sans',
    backgroundColor: 'white',
    padding: '5px 1px 6px 9px',
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  },
  subjectInput: {
    borderRadius: 5,
    border: '1px solid rgb(207, 207, 207)',
    fontFamily: 'Open Sans',
    color: '#59595C',
    fontSize: '0.83rem',
    backgroundColor: 'white',
    // width: '78%',
    // marginLeft: '25px',
    marginTop: '15px',
    padding: '18.5px 14px',
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  },
  displayDate: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: '12px'
  },
  displayBox: {
    padding: '14px 14px',
    height: '150px',
    overflow: 'auto',
    backgroundColor: '#fff',
    borderRadius: '2px'
  },
  displayStoreBox: {
    marginLeft: '25px',
    padding: '14px 14px',
    width: '76.5%',
    height: '150px',
    overflow: 'auto',
    backgroundColor: '#fff',
    borderRadius: '2px'
  },
  showSuccessMessage: {
    height: '250px',
    width: '250px',
    fontFamily: 'Open Sans',
    textAlign: 'center'
  },
  description: {
    fontSize: '0.9rem',
    margin: spacing(2.5, 0),
    textAlign: 'justify',
    textJustify: 'inter-word',
    display: 'inline-block'
  },
  description1: {
    fontSize: '0.9rem',
    marginBottom: spacing(2.5),
    textAlign: 'justify',
    textJustify: 'inter-word'
  },
  storeLoader: {
    position: 'absolute',
    right: '2.5rem',
    top: '45%'
  }
}))
const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})(Box)

export default function InactivePage(props) {
  const styles = useStyles()
  const { location, history, type } = props

  const dateInputRef = useRef()
  const timeInputRef = useRef()

  const [infoOpen, setInfoOpen] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState('en')

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const {
    consent,
    setConsent,
    isTextLoading,
    isSaving,
    store,
    setStore,
    images,
    setImages,
    storeLocation,
    openList,
    setOpenList,
    open,
    setOpen,
    isTngMp,
    setIsTngMp,
    subject,
    setSubject,
    notify,
    setNotify,
    showImgModal,
    setShowImgModal,
    feedback,
    setFeedback,
    showMessage,
    setShowMessage,
    getSearchLocation,
    handleStore,
    onImageClick,
    removePreviewImg,
    handleSubmitFeedback,
    handleSubmitEmployeeFeedback,
    isNoUser,
    isMobileApp
  } = useInactivePageLogic(location, history, type)

  return (
    <Box p={2}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '12px 0'
        }}>
        <Box flex={1}>
          {!isNoUser() && !isMobileApp() && <BackButton {...props} />}
        </Box>
        {!isMobileApp() && isNoUser() && (
          <Box
            flex={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <img
              src={fmLogo}
              style={{ maxWidth: '180px', width: '70%' }}
              alt="fm-logo"
            />
          </Box>
        )}
        <Box flex={1}></Box>
      </Box>

      <Box>
        <Box my={5} mx={1}>
          {type !== 'employeeFeedback' && (
            <>
              <Box>
                <Typography
                  variant="h4"
                  style={{
                    color: '#1E91CF',
                    marginLeft: 24
                  }}>
                  Store Name *
                </Typography>
                <Box m="16px 24px">
                  <Autocomplete
                    loading={isTextLoading}
                    disablePortal
                    options={storeLocation}
                    fullWidth
                    popupIcon={null}
                    closeIcon={null}
                    value={feedback.searchLocation || ''}
                    onChange={(_event, newValue) => {
                      setStore(newValue)
                      setFeedback({
                        ...feedback,
                        searchLocation: newValue
                      })
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select store"
                        variant="outlined"
                        style={{
                          backgroundColor: '#FFF',
                          borderRadius: '4px'
                        }}
                        inputProps={{
                          ...params.inputProps,
                          style: { padding: '9px 5px' }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            ...params.InputProps.style,
                            paddingRight: '9px'
                          },
                          ...(isTextLoading && {
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ paddingRight: '4px' }}>
                                <CircularProgress size={20} />
                              </InputAdornment>
                            )
                          })
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>

              {isNoUser() && (
                <>
                  <Box mt={2}>
                    <Typography
                      variant="h4"
                      style={{
                        color: '#1E91CF',
                        marginLeft: 24
                      }}>
                      Name *
                    </Typography>
                    <TextFieldInput
                      placeholder="Name"
                      value={feedback.customerName}
                      onChange={(e) =>
                        setFeedback({
                          ...feedback,
                          customerName: e.target.value
                        })
                      }
                    />
                  </Box>

                  <Box mt={2}>
                    <Typography
                      variant="h4"
                      style={{
                        color: '#1E91CF',
                        marginLeft: 24
                      }}>
                      Email *
                    </Typography>
                    <TextFieldInput
                      placeholder="Email"
                      value={feedback.customerEmail}
                      onChange={(e) =>
                        setFeedback({
                          ...feedback,
                          customerEmail: e.target.value
                        })
                      }
                    />
                  </Box>

                  <Box mt={2}>
                    <Typography
                      variant="h4"
                      style={{
                        color: '#1E91CF',
                        marginLeft: 24
                      }}>
                      Phone number *
                    </Typography>
                    <TextFieldInput
                      placeholder="123456789"
                      value={feedback.customerMobileNo}
                      onChange={(e) => {
                        if (e.target.value.startsWith('60')) {
                          e.target.value = e.target.value.slice(2)
                          setFeedback({
                            ...feedback,
                            customerMobileNo: e.target.value
                          })
                        } else {
                          setFeedback({
                            ...feedback,
                            customerMobileNo: e.target.value
                          })
                        }
                      }}
                      isPhoneNumber
                    />
                  </Box>
                </>
              )}

              <Box mt={2} mb={1} mx={3}>
                <div className={styles.displayDate}>
                  <Box width="100%">
                    <Typography
                      variant="h4"
                      style={{
                        color: '#1E91CF',
                        marginBottom: 15
                      }}>
                      Date of Visit *
                    </Typography>
                    <form>
                      <TextField
                        id="date"
                        type="date"
                        inputRef={dateInputRef}
                        fullWidth
                        defaultValue={moment().format('YYYY-MM-DD')}
                        InputProps={{
                          className: styles.dateInput,
                          inputProps: { max: moment().format('YYYY-MM-DD') }
                        }}
                        onClick={() => dateInputRef.current.showPicker()}
                        onChange={(date) =>
                          setFeedback(
                            { ...feedback, dateOfVisit: date.target.value },
                            setOpenList(false),
                            setOpen(false)
                          )
                        }
                      />
                    </form>
                  </Box>
                  <Box width="100%">
                    <Typography
                      variant="h4"
                      style={{
                        color: '#1E91CF',
                        marginBottom: 15
                      }}>
                      Time of Visit *
                    </Typography>
                    <form>
                      <TextField
                        id="time"
                        type="time"
                        inputRef={timeInputRef}
                        InputProps={{ className: styles.dateInput }}
                        fullWidth
                        defaultValue={moment(feedback.timeOfVisit).format(
                          'HH:mm'
                        )}
                        onClick={() => timeInputRef.current.showPicker()}
                        onChange={(time) =>
                          setFeedback(
                            { ...feedback, timeOfVisit: time.target.value },
                            setOpenList(false),
                            setOpen(false)
                          )
                        }
                      />
                    </form>
                  </Box>
                </div>
              </Box>
            </>
          )}

          <Box my={2} mx={3}>
            {type === 'employeeFeedback' && (
              <>
                <Typography
                  variant="h4"
                  style={{
                    color: '#1E91CF'
                  }}>
                  Your Voice Matters!
                </Typography>
                <Typography variant="body1" className={styles.description}>
                  Let us know how you feel in the workplace. We are listening,
                  and we are here to help.
                </Typography>
                <Typography variant="body1" className={styles.description1}>
                  Do take a few minutes to complete this form.
                </Typography>
              </>
            )}
            <Box style={{ display: 'flex' }}>
              <Typography
                variant="h4"
                style={{
                  color: '#1E91CF'
                }}>
                Subject
              </Typography>
              {type === 'employeeFeedback' && (
                <IconButton
                  size="small"
                  onClick={() => setInfoOpen(true)}
                  style={{
                    width: '20px',
                    height: '20px'
                  }}>
                  <InfoIcon fontSize="small" style={{ fontSize: '15px' }} />
                </IconButton>
              )}
            </Box>

            <Box onClick={() => setOpen(true)} className={styles.subjectInput}>
              {feedback.title}
            </Box>
            {open && (
              <div className={styles.displayBox}>
                {subject.map((str, idx) => {
                  return (
                    <Box
                      key={idx}
                      onClick={() =>
                        setFeedback(
                          { ...feedback, title: str },
                          setOpen(false),
                          setOpenList(false)
                        )
                      }>
                      <List key={idx}>{str}</List>
                      <Divider />
                    </Box>
                  )
                })}
              </div>
            )}
          </Box>
          {type === 'employeeFeedback' && (
            <>
              <Box>
                <Typography
                  variant="h4"
                  style={{
                    color: '#1E91CF',
                    marginLeft: 24,
                    marginTop: 10
                  }}>
                  Position
                </Typography>
                <TextFieldInput
                  placeholder="Position"
                  value={feedback.position}
                  onChange={(e) =>
                    setFeedback(
                      { ...feedback, position: e.target.value },
                      setOpen(false)
                    )
                  }
                />
              </Box>
              <Box mt="14px">
                <Typography
                  variant="h4"
                  style={{
                    color: '#1E91CF',
                    marginLeft: 24
                  }}>
                  Store Name
                </Typography>
                <Box m="16px 24px">
                  <Autocomplete
                    loading={isTextLoading}
                    disablePortal
                    options={storeLocation}
                    fullWidth
                    freeSolo
                    disableClearable
                    value={feedback.storeName}
                    onChange={(_event, newValue) => {
                      setFeedback({
                        ...feedback,
                        storeName: newValue
                      })
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select store"
                        variant="outlined"
                        style={{
                          backgroundColor: '#FFF',
                          borderRadius: '4px'
                        }}
                        inputProps={{
                          ...params.inputProps,
                          style: { padding: '9px 5px' }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          ...(isTextLoading && {
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ paddingRight: '4px' }}>
                                <CircularProgress size={20} />
                              </InputAdornment>
                            )
                          })
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </>
          )}

          <Box>
            <Typography
              variant="h4"
              style={{
                color: '#1E91CF',
                marginLeft: 24,
                marginTop: 10
              }}>
              Description *
            </Typography>
            <TextFieldInput
              placeholder="Description (Maximum 4000 characters)"
              value={feedback.message}
              onChange={(e) =>
                setFeedback(
                  { ...feedback, message: e.target.value },
                  setOpenList(false),
                  setOpen(false)
                )
              }
              multiline
            />
          </Box>
          <Box mt={-0.5}>
            <Row px={3}>
              <Typography
                variant="h4"
                style={{
                  color: '#1E91CF',
                  width: '200px',
                  marginTop: '20px'
                }}>
                Attachments
              </Typography>
            </Row>
            <Row px={3}>
              <Typography
                variant="body2"
                style={{
                  color: '#59595C',
                  marginTop: '8px'
                }}>
                Receipt is required for service & product related feedback
              </Typography>
            </Row>
            <Row px={3}>
              <Box display="flex">
                {[0, 1, 2].map((i, idx) => {
                  return (
                    <ImageInput
                      setImages={setImages}
                      images={images}
                      idx={idx}
                      key={idx}
                      onImageClick={onImageClick}
                      setNotify={setNotify}
                      isTngMp={isTngMp}
                    />
                  )
                })}
              </Box>
            </Row>
            <Row
              px={3}
              style={{
                fontSize: '12px',
                color: '#59595C'
              }}>
              <p style={{ margin: 0 }}>
                <b>Note:</b> Only jpg or png files are supported (2MB limit)
              </p>
            </Row>
          </Box>

          {type === 'employeeFeedback' && (
            <FormControlLabel
              control={
                <Checkbox
                  value={consent}
                  onChange={(e) => setConsent(e.target.checked)}
                  style={{ marginTop: '-8px' }}
                />
              }
              label='By checking this box, I hereby confirm that all the information provided is true and accurate. I understand that any false information or bad intention, including any form of "fitnah" against another party, is strictly prohibited and may result in disciplinary action.'
              labelPlacement="end"
              style={{
                alignItems: 'flex-start',
                padding: '0 20px',
                marginTop: '12px'
              }}
            />
          )}

          <Box display="flex" justifyContent="center" my={8} marginTop="70px">
            <Button
              title="Submit"
              handleClick={() => {
                if (type === 'employeeFeedback') handleSubmitEmployeeFeedback()
                else handleSubmitFeedback()
              }}
              disabled={isSaving || isTextLoading}
            />
          </Box>
        </Box>

        <Backdrop
          open={showImgModal.show}
          onClick={() =>
            setShowImgModal({ show: false, image: '', currIdx: null })
          }
          className={styles.backdrop}>
          <Box>
            <div className={styles.modalImgBox}>
              <img src={showImgModal.image} className={styles.modalImg} />
            </div>
            <Typography
              className={styles.remove}
              onClick={() => removePreviewImg(showImgModal.currIdx)}>
              remove image
            </Typography>
          </Box>
        </Backdrop>
        <Backdrop
          open={showMessage.show}
          onClick={() => setShowMessage({ show: false })}
          className={styles.backdrop}>
          <Box className={styles.showSuccessMessage}>
            <Typography variant="h3">THANK YOU FOR YOUR FEEDBACK</Typography>
            <Typography variant="body2">
              Your message is well received. We will look into this and get back
              to you as soon as possible
            </Typography>
            <Divider />
            <Typography
              className={styles.close}
              onClick={() => setShowMessage({ show: false })}>
              Close
            </Typography>
          </Box>
        </Backdrop>
      </Box>
      <DialogInfo
        open={infoOpen}
        handleClose={() => setInfoOpen(false)}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <Notification
        show={notify.show}
        severity={notify.severity}
        message={notify.message}
        onClose={() => setNotify({ show: false, severity: '', message: '' })}
      />
    </Box>
  )
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '10px',
    fontSize: '0.87rem'
  }
}))(MuiDialogActions)

const DialogButton = withStyles((theme) => ({
  root: {
    fontSize: '0.8rem',
    padding: 0
  }
}))(MuiButton)

const DialogInfo = (props) => {
  const { handleClose, open, selectedLanguage, setSelectedLanguage } = props
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent dividers>
        <Typography gutterBottom style={{ marginBottom: '15px' }}>
          {SubjectInfo[selectedLanguage].subtitle}
        </Typography>

        {[1, 2, 3, 4, 5, 6, 7].map((element) => {
          return (
            <>
              <Typography
                gutterBottom
                color="primary"
                style={{ fontWeight: 'bold' }}>
                {SubjectInfo[selectedLanguage][`option${element}`].title}
              </Typography>
              <Typography
                gutterBottom
                style={{
                  fontSize: '12px',
                  marginBottom: '8px'
                }}>
                {SubjectInfo[selectedLanguage][`option${element}`].desc}
              </Typography>
            </>
          )
        })}
      </DialogContent>
      <DialogActions>
        <DialogButton
          onClick={() =>
            setSelectedLanguage(selectedLanguage === 'bm' ? 'en' : 'bm')
          }
          color="primary">
          {SubjectInfo[selectedLanguage].button1}
        </DialogButton>
        <DialogButton
          onClick={handleClose}
          color="primary"
          style={{ minWidth: '0', marginLeft: '16px' }}>
          {SubjectInfo[selectedLanguage].button2}
        </DialogButton>
      </DialogActions>
    </Dialog>
  )
}
