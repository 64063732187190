import React, { useState, useCallback, useEffect, useContext } from 'react'
import DesktopContentPaper from 'src/components/DesktopComponents/DesktopContentPaper/DesktopContentPaper'
import useChatList from 'src/pages/Chat/hooks/useChatList'
import './ChatPageDesktop.scss'
import {
  ChatItem,
  Paper,
  TextFieldInput,
  Button,
  ImageInput,
  BackButton,
  Notification,
  Spinner
} from 'src/components'
import useChatPageLogic from '../hooks/useChatPageLogic'
import { format } from 'date-fns'
import {
  Box,
  makeStyles,
  withStyles,
  Typography,
  Backdrop
} from '@material-ui/core'
import { useHistory } from 'react-router'
import _ from 'lodash'
import { DesktopNavigationContext } from 'src/context/DesktopNavigationContext'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: palette.secondary.light
  },
  image: {
    width: '100%',
    height: '40%',
    objectFit: 'cover'
  },
  commentImgBox: {
    justifyContent: (props) => (props === 1 && 'flex-end') || 'flex-start'
  },
  commentImg: {
    width: 50,
    height: 50,
    borderRadius: 10
    // marginLeft: spacing(2)
  },
  status: {
    color: ({ state }) => state && checkStatus(state.status),
    textTransform: 'uppercase'
  },
  icon: {
    marginLeft: 7.5,
    marginRight: 7.5,
    fontSize: '0.35rem',
    color: palette.grey[100],
    alignItems: 'center'
  },
  type: {
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  faq: {
    borderBottom: `2px solid ${palette.primary.light}`
  },
  lastReply: {
    padding: spacing(0, 3),
    marginTop: spacing(0.5),
    marginBottom: spacing(2),
    fontFamily: 'Open Sans',
    color: '#59595C'
  },
  bubbleDate: {
    float: (props) => (props === 1 && 'right') || 'left'
  },
  content: {
    width: '80%',
    float: (props) => (props === 1 && 'right') || 'left'
  },
  paper: {
    margin: spacing(0.75, 0),
    wordWrap: 'break-word',
    backgroundColor: (props) =>
      (props === 1 && palette.secondary.main) || palette.common.white
  },
  msg: {
    color: (props) =>
      (props === 1 && palette.common.white) || palette.text.primary
  },
  backButton: {
    padding: spacing(0.5, 1.5),
    marginTop: '12px',
    marginLeft: '4px',
    position: 'absolute',
    background: 'white',
    borderRadius: '20px'
  },
  date: {
    fontFamily: 'Open Sans',
    color: '#59595C',
    whiteSpace: 'nowrap'
  },
  modalimg: {
    height: '90%',
    width: '90%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
    // width: '90vw'
  },
  backdrop: {
    zIndex: 10,
    color: '#fff'
  },
  remove: {
    textAlign: 'center',
    marginTop: spacing(1),
    padding: spacing(1, 2),
    backgroundColor: palette.warning.main,
    color: palette.common.white
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chat_container: {
    marginTop: '60px'
  }
}))

const checkStatus = (status) => {
  switch (status) {
    case 'pending':
      return '#F96232'
    default:
      return '#1E91CF'
  }
}

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})(Box)

const ChatPageDesktop = (props) => {
  const NavigationContext = useContext(DesktopNavigationContext)
  const styles = useStyles()
  const { location, history } = props
  const { state } = location
  const token = global.jwtToken
    ? global.jwtToken
    : sessionStorage.getItem('token') !== null &&
      sessionStorage.getItem('token') !== 'TOKEN_HERE'
    ? sessionStorage.getItem('token')
    : null
  let platform = state.platform

  const [selectedChat, setSelectedChat] = useState({})

  const { chats, isChatListLoading } = useChatList(token, platform)

  const {
    chatHistory,
    isLoadingChat,
    handleSubmit,
    date,
    data,
    setData,
    isSaving,
    images,
    setImages,
    notify,
    setNotify,
    showImgModal,
    setShowImgModal,
    onImageClick,
    removePreviewImg
  } = useChatPageLogic(selectedChat, selectedChat?.issueId, platform)

  const isTngMp = props.location.platform === 'tngmp' ? true : false

  const handleSelectChat = (chatItem) => {
    NavigationContext.handleUpdateBreadcrumbs(['Chat', chatItem.title])
    setSelectedChat(chatItem)
  }

  useEffect(() => {
    // console.log({ chats, isChatListLoading })
    if (chats.length && !isChatListLoading) {
      handleSelectChat({ ...chats[0] })
    }
  }, [chats, isChatListLoading])

  useEffect(() => {
    if (NavigationContext.navigationList.length === 1) {
      NavigationContext.handleUpdateBreadcrumbs(['Chat'])
    }
  }, [])

  return (
    <div>
      <DesktopContentPaper>
        {isChatListLoading && _.isEmpty(selectedChat) ? (
          <div className="chatpage-desktop-container-loading">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="container-chat-page">
              <div className="left-col">
                {!isChatListLoading &&
                  chats.map((chatItem, index) => (
                    <div
                      key={index}
                      className={`container-chat-item ${
                        chatItem.issueId === selectedChat?.issueId && 'selected'
                      }`}
                      onClick={() => handleSelectChat(chatItem)}>
                      <ChatItem chat={chatItem} />
                    </div>
                  ))}
              </div>
              <div className="right-col">
                {!_.isEmpty(selectedChat) && (
                  <>
                    <ChatItem chat={selectedChat} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      p={1.5}
                      mx={1.5}
                      mt={1.5}
                      mb={2}
                      bgcolor="primary.light"
                      borderRadius={10}>
                      {!isLoadingChat && chatHistory.length > 0 && (
                        <Box style={{ height: '450px', overflow: 'auto' }}>
                          {chatHistory.map((chat, idx) => {
                            return (
                              <CommentSection
                                data={chat}
                                key={idx}
                                onImageClick={onImageClick}
                              />
                            )
                          })}
                        </Box>
                      )}
                      {!isLoadingChat && chatHistory.length <= 0 && (
                        <Typography>
                          Empty chat, start a new one by submitting a comment
                          down below
                        </Typography>
                      )}
                      {isLoadingChat && <Spinner className={styles.spinner} />}
                    </Box>
                    <Typography
                      variant="h4"
                      style={{
                        color: '#1E91CF',
                        marginLeft: 24
                      }}>
                      COMMENTS
                    </Typography>
                    <TextFieldInput
                      multiline
                      value={data.message}
                      placeholder="Let us know about your order"
                      onChange={(e) =>
                        setData({ ...data, message: e.target.value })
                      }
                    />
                    <Row px={3}>
                      <Typography
                        variant="h4"
                        style={{
                          color: '#1E91CF'
                        }}>
                        ATTACHMENTS
                      </Typography>
                      <Box
                        display="flex"
                        style={{ position: 'relative', top: 40, right: 90 }}>
                        {[0, 1, 2].map((i, idx) => {
                          return (
                            <ImageInput
                              setImages={setImages}
                              images={images}
                              key={idx}
                              idx={idx}
                              onImageClick={onImageClick}
                              setNotify={setNotify}
                              isTngMp={isTngMp}
                            />
                          )
                        })}
                      </Box>
                    </Row>
                    <Box display="flex" justifyContent="center" mb={1.5} my={5}>
                      <Button
                        title="Submit"
                        handleClick={() => handleSubmit()}
                        disabled={isSaving}
                      />
                    </Box>
                  </>
                )}
              </div>
            </div>
            <Notification
              show={notify.show}
              severity={notify.severity}
              message={notify.message}
              onClose={() =>
                setNotify({ show: false, severity: '', message: '' })
              }
            />
            <Backdrop
              open={showImgModal.show}
              onClick={() =>
                setShowImgModal({ show: false, image: '', currIdx: null })
              }
              className={styles.backdrop}>
              <Box>
                <img src={showImgModal.image} className={styles.modalimg} />
                <Typography
                  className={styles.remove}
                  onClick={() => removePreviewImg(showImgModal.currIdx)}>
                  remove image
                </Typography>
              </Box>
            </Backdrop>
          </>
        )}
      </DesktopContentPaper>
    </div>
  )
}

function CommentSection({ data, onImageClick }) {
  const styles = useStyles(data.isCustomerMessage)
  return (
    <Box display="block">
      <Box className={styles.content}>
        <Paper
          display="block"
          elevation={0}
          borderRadius={10}
          className={styles.paper}>
          {data.message && data.message.length > 0 ? (
            data.message.map((message, idx) => {
              return (
                <Typography variant="body2" className={styles.msg} key={idx}>
                  {message}
                </Typography>
              )
            })
          ) : (
            <Typography variant="body2" className={styles.msg}>
              {data.message}
            </Typography>
          )}
        </Paper>
        {data && data.image && data.image.length > 0 && (
          <Row className={styles.commentImgBox} my={1.5}>
            {data.image.map((src, idx) => {
              if (src !== '') {
                return (
                  <img
                    src={src}
                    className={styles.commentImg}
                    key={idx}
                    onClick={() => onImageClick(src)}
                  />
                )
              }
            })}
          </Row>
        )}
        <Typography variant="body2" className={styles.bubbleDate}>
          {`${format(new Date(data.issueDetailDateTime), 'd MMMM y hh:mm a')}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default ChatPageDesktop
