import React, { useEffect, useContext } from 'react'
import {
  Box,
  Typography,
  makeStyles,
  withStyles,
  Link
} from '@material-ui/core'
import { Paper, Spinner, DesktopPanel } from 'src/components'
import useFaqPageLogic from '../hooks/useFaqPageLogic'
import { DesktopNavigationContext } from 'src/context/DesktopNavigationContext'
import clsx from 'clsx'
import './FAQPage.scss'
import DesktopContentPaper from 'src/components/DesktopComponents/DesktopContentPaper/DesktopContentPaper'

const useStyles = makeStyles(({ palette, spacing }) => ({
  h1: {
    color: palette.primary.main,
    textAlign: 'center'
  },
  faq: {
    textAlign: 'left',
    width: 332,
    fontFamily: 'Open Sans',
    fontSize: '19px',
    color: '#6D6E71'
  },
  answer: {
    margin: spacing(2.5, 0)
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F1F1F3'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
})(Box)

export default function FAQDesktopPage(props) {
  const NavigationContext = useContext(DesktopNavigationContext)
  const styles = useStyles()

  const {
    faqList,
    userPlatform,
    isLoading,
    category,
    filteredFaq,
    setFilteredFaq,
    faq,
    handleSelectCategory
  } = useFaqPageLogic('desktop', props.location, NavigationContext)

  useEffect(() => {
    let tempFaqList = [...faqList]
    setFilteredFaq(tempFaqList.filter((item) => item.category === category))
  }, [category])

  return (
    <>
      {isLoading && <Spinner className={styles.spinner} />}
      {!isLoading && (
        <div>
          <Box mx={3} display="flex" justifyContent="flex-start"></Box>
          {/* <Box display="flex" justifyContent="center"> */}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <Paper
              className={clsx(styles.paper, 'faq-page-paper')}
              elevation={1}
              borderRadius={10}> */}
            <DesktopContentPaper className="faq-page-paper">
              <Row
                className="left-section"
                width="30%"
                display="flex"
                flexDirection="column">
                {faq &&
                  faq.length > 0 &&
                  faq.map((item, idx) => {
                    return (
                      <DesktopPanel
                        {...props}
                        icon={item.icon}
                        topic={item.category}
                        path={item.path}
                        key={idx}
                        item={item}
                        platform={userPlatform}
                        category={category}
                        setCategory={handleSelectCategory}
                      />
                    )
                  })}
              </Row>
              <Box
                width="70%"
                display="flex"
                justifyContent="flex-start"
                className="right-section"
                flexDirection="column">
                {filteredFaq.map((item, idx) => {
                  const message = item.answer
                  const msgArr = message.split('\n')
                  return (
                    <div key={idx} className="faq-box">
                      <Typography variant="body1" className="topic">
                        {item.topic}
                      </Typography>
                      {msgArr &&
                        msgArr.length > 0 &&
                        msgArr.map((str, index) => {
                          if (
                            str.slice(0, 4) == 'http' ||
                            str.slice(0, 5) == 'https'
                          ) {
                            const url = str.replace(/\s/g, '')
                            return (
                              <Link
                                key={index}
                                href={url}
                                onClick={() => window.open(url, '_self')}
                                variant="body1"
                                className={clsx('faq-answer', 'faq-link')}>
                                {str}
                              </Link>
                            )
                          } else if (
                            str === 'You have keyed in the correct number' ||
                            str ===
                              'You are using a mobile number starting with +60 (for Malaysia) or +65 (for Singapore)' ||
                            str ===
                              'Your have unblocked all the 5-digits shortcode (e.g. 6xxxx) from your mobile phone’s message (SMS) App'
                          ) {
                            return (
                              <div key={index} className="faq-bullet-list">
                                <ul>
                                  <li>{str}</li>
                                </ul>
                              </div>
                            )
                          } else {
                            return (
                              <Typography
                                key={index}
                                variant="body1"
                                className={clsx(styles.answer, 'faq-answer')}>
                                {str.split(' ').map((item) => {
                                  if (
                                    item.startsWith('https') ||
                                    item.startsWith('http')
                                  ) {
                                    const itemUrl = item.replace(/\s/g, '')
                                    return (
                                      <>
                                        <Link
                                          href={itemUrl}
                                          onClick={() =>
                                            window.open(itemUrl, '_self')
                                          }
                                          variant="body1"
                                          className={clsx(
                                            'faq-answer',
                                            'faq-link'
                                          )}>
                                          {item}
                                        </Link>
                                        <span> </span>
                                      </>
                                    )
                                  } else {
                                    return <span>{item} </span>
                                  }
                                })}
                              </Typography>
                            )
                          }
                        })}
                    </div>
                  )
                })}
              </Box>
            </DesktopContentPaper>
            {/* </Paper> */}
            {/* </Box> */}
          </div>
        </div>
      )}
    </>
  )
}
