import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, withStyles } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { format } from 'date-fns'

const useStyles = makeStyles(({ palette, spacing }) => ({
  image: {
    width: 100,
    height: 75,
    borderRadius: 5
  },
  type: {
    textTransform: 'uppercase',
    fontWeight: 600
  },
  status: {
    color: (props) => checkStatus(props.status),
    textTransform: 'uppercase'
  },
  icon: {
    marginLeft: 7.5,
    marginRight: 7.5,
    fontSize: '0.35rem',
    color: palette.grey[100],
    alignItems: 'center'
  },
  date: {
    fontSize: '12px',
    fontFamily: 'Open Sans',
    color: '#59595C',
    whiteSpace: 'nowrap',
    textAlign: 'right'
  },
  formatdate: {
    fontSize: '12px',
    fontFamily: 'Open Sans',
    color: '#59595C'
  },
  h4: {
    color: palette.primary.main
  },
  bubble: {
    color: palette.common.white,
    backgroundColor: palette.warning.main,
    padding: spacing(0.25, 0.75),
    margin: spacing(0, 0.75),
    borderRadius: '50%'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row'
  }
})(Box)

const checkStatus = (status) => {
  switch (status) {
    case 'pending':
      return '#F96232'
    default:
      return '#1E91CF'
  }
}

const ChatItem = ({ chat }) => {
  const styles = useStyles(chat)
  const [width, setWidth] = useState(window.innerWidth)

  let isMobile = width <= 350

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (
    <Box mx={1}>
      <Row justifyContent="space-between" my={1}>
        <Row alignItems="center">
          <Typography variant="h4" className={styles.h4}>
            {chat.orderNumber ? `Order #` + chat.orderNumber : `Feedback`}
          </Typography>
          {(chat.hasNewMessage && chat.hasNewMessage > 0 && (
            <Typography variant="h4" className={styles.bubble}>
              {chat.hasNewMessage}
            </Typography>
          )) ||
            ''}
        </Row>
        {!isMobile && (
          <Typography className={styles.date}>
            {format(new Date(chat.createdAt), 'dd MMMM y')}
            <br />
            {format(new Date(chat.createdAt), ' hh:mm a')}
          </Typography>
        )}
      </Row>
      {isMobile && (
        <Row>
          <Typography className={styles.date}>
            {format(new Date(chat.createdAt), 'dd MMMM y hh:mm a')}
          </Typography>
        </Row>
      )}
      <Row alignItems="center" my={0.5}>
        <Typography variant="h4" className={styles.type}>
          {chat.title}
        </Typography>
        <FiberManualRecord className={styles.icon} />
        <Typography variant="h4" className={styles.status}>
          {chat.issueStatus}
        </Typography>
      </Row>
      <Typography
        variant="body2"
        className={styles.formatdate}>{`Last reply message: ${format(
        new Date(chat.updatedAt),
        'dd MMMM y hh:mm a'
      )}`}</Typography>
    </Box>
  )
}

export default ChatItem
